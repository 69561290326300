import React, { useEffect } from "react"
import { gsap } from "gsap"
import Header from "./header"
import Footer from "./footer"
import CookieConsent from 'react-cookie-consent'

const Layout = ({ location, global, children }) => {

  useEffect(() =>{

    // dectect when sections/slices are in viewport
    const check = (entries) => entries.forEach(entry => {
      entry.target.classList.add("is-active", entry.isIntersecting)
    });
    const options = {
      threshold: 0.5
    }

    const Obs = new IntersectionObserver(check, options)
    const sections = document.querySelectorAll(".section")
    sections.forEach(el => Obs.observe(el))


    const fadeUp = document.querySelectorAll(`.section.is-active .fade-up`)
    fadeUp.forEach((element, index) => {
      gsap.to(element, {
        //delay: 0.2 * ( index + 1 ),
        duration: 0.4,
        opacity: '1',
        y: '0',
      })
    })


    return () => sections.forEach(el => Obs.unobserve(el))


  }, [])

  // const [loader, setLoader] = useState(true)

  // useEffect(()=>{
  //   setTimeout(()=> {
  //      setLoader(false)
  //   }, 1000)
  // }, [])

  // console.log(`layout global`, global)
  // console.log(`layout location`, location)

  return (
    <>
      <div className="global-wrapper [ overflow-x-hidden overflow-y-auto ]">
        <Header global={global} />
        <main>{children}</main>
        <Footer global={global} />
      </div>
      <CookieConsent
        containerClasses="[CookieConsent] w-full sticky bottom-0 z-[9999] left-0 bg-black text-gray-300 p-6 flex flex-wrap gap-4 items-center justify-between"
        disableStyles="true"
        location="none"
        buttonText="Accept"
        buttonClasses="rounded-md bg-white text-black px-4 py-1"
        enableDeclineButton
        declineButtonText="Decline"
        declineButtonClasses="rounded-md border border-white border-opacity-[24%] text-gray-300 px-4 py-1"
        buttonWrapperClasses="flex gap-4 grid-cols-2"
        cookieName="gatsby-gdpr-google-analytics"
        onAccept={()=>document.location.reload()}
        onDecline={()=>document.location.reload()}
      >
        <p>This website uses cookies and analytics to collect data. We use it to understand how you use our website so we can improve design and functionality. Please choose your cookie preference:</p>
      </CookieConsent>
    </>
  );
};

export default Layout;
