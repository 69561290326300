import React from 'react'
import Hero from './slices/hero'
import AppCarousel from './slices/appCarousel'
import BoxCarousel from './slices/boxCarousel'
import Accordions from './slices/accordions'
import Locations from './slices/locations'


const SliceZone = ({ sliceZone, page, global, title, data, lang, altLangs, slug, path, pageContext, id, prismicId }) => {
  // console.log(`sliceZone`, sliceZone)
  const sliceComponents = {
    hero: Hero,
    app_carousel: AppCarousel,
    box_carousel: BoxCarousel,
    accordions: Accordions,
    locations: Locations
  }

  const sliceZoneContent = sliceZone.map((slice, index) => {

    if (slice) {
      const SliceComponent = sliceComponents[slice.slice_type]
      if (SliceComponent) {
        return (
          <SliceComponent
            id={id}
            prismicId={prismicId}
            lang={lang}
            altLangs={altLangs}
            path={path}
            pageContext={pageContext}
            slug={slug}
            slice={slice}
            key={`slice-${index}`}
            index={index}
            page={page}
            global={global}
            title={title}
            data={data}
          />
        )
      }
    }
    return null
  })

  return sliceZoneContent
}

export default SliceZone
