import React, { useState } from 'react';
import styled from 'styled-components'
import AccordionItem from './accordionItem.js'
import arrow from '../images/arrow.svg'
import close from '../images/close.svg'
import closeCircle from '../images/close-circle.svg'
import phone from '../images/phone.svg'
import link from '../images/link.svg'
import map from '../images/map.svg'
import mail from '../images/mail.svg'



const StyledModal = styled.div`

  &[aria-hidden="true"] {
    transition: .2s linear;
  }
  &[aria-hidden="false"] {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
    transition: transform .3s cubic-bezier(.55,.055,.675,.19);
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0px;
    -webkit-box-shadow: none;
    background-color: #1f1f1f;
  }
`

const StyledInner = styled.div`
@media (min-width: 1024px) {
  height: calc(75vh - 220px);
  min-height: calc( 600px - 220px);

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0px;
    -webkit-box-shadow: none;
    background-color: #1f1f1f;
  }
}
`



const LocationModal = ({ loc, activeLocation, toggleLocation }) => {

  const [activeAccordion, setActiveAccordion] = useState(null)
  const toggleAccordion = (id) => {
    activeAccordion === id ? setActiveAccordion(null) : setActiveAccordion(id)
  }

  // console.log(`loc`, loc)
  
  const location = loc.location.document.data

  return (
    <>
      <button
        className={`[ flex items-center justify-between w-full ][ py-4 ][ border-b border-white border-opacity-[24%] ][ text-left ][ md:transition md:hover:bg-gray-900 md:hover:text-red ][ ${activeLocation === loc.location.id ? "bg-gray-900 text-red" : "" } ]`}
        aria-controls={`loc-${loc.location.id}`}
        aria-expanded={ activeLocation === loc.location.id ? 'true' : 'false'}
        onClick={() => toggleLocation(loc.location.id)}
      >
        <span>{ location.title }</span>
        <svg className={`[ text-white md:mr-3 ][ ${activeLocation === loc.location.id ? 'md:hidden' : ''} ]`} width="16" height="18" viewBox={arrow.viewBox} aria-hidden="true">
          <use xlinkHref={arrow.url}/>
        </svg>
        <svg className={`[ text-red md:mr-3 ][ ${activeLocation === loc.location.id ? 'hidden md:block' : 'hidden'} ]`} width="16" height="18" viewBox={closeCircle.viewBox} aria-hidden="true">
          <use xlinkHref={closeCircle.url}/>
        </svg>
      </button>
      <StyledModal id={`loc-${loc.location.id}`} className="[ fixed lg:absolute top-0 left-0 lg:left-1/2 z-[100] ][ w-full h-screen lg:h-[75vh] lg:min-h-[600px] lg:w-1/2 ][ bg-black ][ opacity-0 visibility-hidden translate-x-full ]" aria-hidden={ activeLocation === loc.location.id ? 'false' : 'true'}>
          <header className="[ md:flex md:items-center md:justify-between ][ text-right md:text-left ][ md:mt-40 ]">
            <button className="[ p-6 md:p-5 ][ md:order-1 ][ md:bg-gray-900 md:rounded-lg ][ md:hover:bg-gray-800 md:transition ]" onClick={() => toggleLocation(null)}>
              <svg className="[ text-white ]" width="14" height="14" viewBox={close.viewBox} aria-hidden="true">
                <use xlinkHref={close.url}/>
              </svg>
            </button>
            <h2 className="[ text-3xl text-left font-extended text-white font-medium ][ border-t border-white border-opacity-[24%] md:border-t-0 ][ px-6 pt-6 md:pt-0 ]">{ location.title }</h2>
          </header>
          <StyledInner className="[ overflow-x-hidden overflow-y-auto ][ lg:mt-7 ]">
            <div className="[ p-6 lg:py-0 ]">
              <ul className="[ flex items-center lg:flex-wrap ][ -mx-1.5 ]">
                {location.phone != null &&
                  <li className="[ flex-1 lg:w-full lg:flex-none ][ px-1.5 lg:mb-3 ]">
                    <a className="[ flex items-center justify-center lg:justify-start w-full ][ bg-white text-black font-medium text-lg leading-none ][ rounded-lg p-2.5 ][ lg:bg-transparent lg:text-gray-300 lg:font-normal lg:p-0 lg:rounded-none ]" href={`tel:${location.phone}`}>
                      <svg className="[ text-white mr-2 ][ lg:hidden ]" width="15" height="15" viewBox={phone.viewBox} aria-hidden="true">
                        <use xlinkHref={phone.url}/>
                      </svg>
                      <span className="[ lg:hidden ]">Phone</span>
                      <span className="[ hidden lg:block ]">{location.phone}</span>
                    </a>
                  </li>
                }
                {location.email != null &&
                  <li className="[ flex-1 lg:flex-none ][ px-1.5 ]">
                    <a className="[ flex items-center justify-center w-full ][ border border-white border-opacity-[24%] ][ text-white font-medium text-lg leading-none ][ rounded-lg p-2.5 ][ lg:bg-transparent lg:border-0 lg:text-gray-300 lg:font-normal lg:p-0 lg:rounded-none lg:underline lg:hover:text-red ]" href={`mailto:${location.email}`}>
                      <svg className="[ text-white mr-2 ][ lg:hidden ]" width="15" height="15" viewBox={mail.viewBox} aria-hidden="true">
                        <use xlinkHref={mail.url}/>
                      </svg>
                      <span className="[ lg:hidden ]">Email</span>
                      <span className="[ hidden lg:block ]">{location.email}</span>
                    </a>
                  </li>
                }
                {location.website.url != null &&
                  <li className="[ flex-1 lg:flex-none ][ px-1.5 ]">
                    <a className="[ flex items-center justify-center w-full ][ border border-white border-opacity-[24%] ][ text-white font-medium text-lg leading-none ][ rounded-lg p-2.5 ][ rounded-lg p-2.5 ][ lg:bg-transparent lg:border-0 lg:text-gray-300 lg:font-normal lg:p-0 lg:rounded-none lg:underline lg:hover:text-red ]" href={location.website.url} target="_blank" rel="noopener noreferrer">
                      <svg className="[ text-white mr-2 ][ lg:hidden ]" width="16" height="8" viewBox={link.viewBox} aria-hidden="true">
                        <use xlinkHref={link.url}/>
                      </svg>
                      <span>Web</span>
                    </a>
                  </li>
                }
                {location.map.url != null &&
                  <li className="[ flex-1 lg:flex-none ][ px-1.5 ]">
                    <a className="[ flex items-center justify-center w-full ][ border border-white border-opacity-[24%] ][ text-white font-medium text-lg leading-none ][ rounded-lg p-2.5 ][ rounded-lg p-2.5 ][ lg:bg-transparent lg:border-0 lg:text-gray-300 lg:font-normal lg:p-0 lg:rounded-none lg:underline lg:hover:text-red ]" href={location.map.url} target="_blank" rel="noopener noreferrer">
                      <svg className="[ text-white mr-2 ][ lg:hidden ]" width="15" height="16" viewBox={map.viewBox} aria-hidden="true">
                        <use xlinkHref={map.url}/>
                      </svg>
                      <span>Map</span>
                    </a>
                  </li>
                }
              </ul>
              <div className="[ mt-5 lg:mt-12 pt-4 md:pt-6 lg:pt-7 ][ border-t border-white border-opacity-[24%] ]">
                { location.service_hours.richText.length > 0 &&
                  <AccordionItem title="Service hours" content={location.service_hours.richText} id={`${loc.location.id}-0`} active={activeAccordion} toggleAccordion={toggleAccordion} />
                }
                { location.cost.richText.length > 0 &&
                  <AccordionItem title="How much does it cost to ride?" content={location.cost.richText} id={`${loc.location.id}-2`} active={activeAccordion} toggleAccordion={toggleAccordion} />
                }
                { location.payment_options.richText.length > 0 &&
                  <AccordionItem title="Payment options" content={location.payment_options.richText} id={`${loc.location.id}-1`} active={activeAccordion} toggleAccordion={toggleAccordion} />
                }
                { location.dogs.richText.length > 0 &&
                  <AccordionItem title="Do you accept dogs?" content={location.dogs.richText} id={`${loc.location.id}-3`} active={activeAccordion} toggleAccordion={toggleAccordion} />
                }
                { location.bikes.richText.length > 0 &&
                  <AccordionItem title="Can I bring my bike?" content={location.bikes.richText} id={`${loc.location.id}-4`} active={activeAccordion} toggleAccordion={toggleAccordion} />
                }
                { location.other_items.length > 0 && location.other_items[0].title1 != null &&
                  <>
                    {location.other_items.map((item,index) => (
                      <AccordionItem key={`faqs-${index}`} title={item.title1} content={item.content.richText} id={`${loc.location.id}-o-${index}`} active={activeAccordion} toggleAccordion={toggleAccordion} />
                    ))}
                  </>
                }
              </div>
            </div>
          </StyledInner>
      </StyledModal>
    </>
  )
}


export default LocationModal
