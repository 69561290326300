import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components'
import { PrismicRichText } from '@prismicio/react'
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import caret from '../../images/caret-down.svg'



if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}



const StyledHeading = styled.div`
  h1,
  h2 {
    color: #fff;
    font-family: "Maison Neue Ext", "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 32px;
    font-weight: 500;
    line-height: 1;

    @media (min-width: 1024px) {
      font-size: 48px;
    }
  }
`


const StyledPanel = styled.div`
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.4s cubic-bezier(0, 1, 0, 1) 0s;

  &.is-active {
    max-height: 9999px;
    transition: max-height 0.4s cubic-bezier(1, 0, 1, 0) 0s;
  }
`


const Accordions = ({ slice, global }) => {

  const section = useRef(null)

  const [activeAccordion, setActiveAccordion] = useState(null)

  const toggleAccordion = (id) => {
    activeAccordion === id ? setActiveAccordion(null) : setActiveAccordion(id)
  }


  // scroll animations
  useEffect(() =>{
    const element = section.current
    gsap.to(
      element.querySelector('.SectionTitle'),
      {
        duration: 0.4,
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: element,
          start: "top 66%",
        },
      }
    )
  }, [])

  // console.log(`accordions slice`, slice)

  return (
    <section id={slice.primary.anchor_id} className="[ AccordionsSlice Section ]" ref={section}>
      <div className="container">
        {slice.primary.title1.richText.length > 0 &&
          <StyledHeading className="SectionTitle fade-up [ mb-6 md:mb-8 lg:mb-12 ]">
            <PrismicRichText field={slice.primary.title1.richText} />
          </StyledHeading>
        }
        {slice.items.map((item,index) => (
          <React.Fragment key={`acc_${slice.id}_${index}`}>
            <h3
              className="[ text-white text-lg lg:text-3xl font-medium ]"
            >
              <button
                id={`acc-head_${slice.id}_${index}`}
                className="[ flex justify-between items-center ][ w-full ][ text-left ]"
                aria-expanded={ activeAccordion === `${slice.id}_${index}` ? "true" : "false"}
                aria-controls={`acc-panel_${slice.id}_${index}`}
                onClick={() => toggleAccordion(`${slice.id}_${index}`)}
              >
                <span className="[ block mr-4 ]">
                  <span className="[ hidden md:inline-block w-12 lg:w-20 ][ text-gray-600 text-left ]">{index + 1}</span>
                  <span>{item.title1.richText[0].text}</span>
                </span>
                <svg
                  className={`[ block w-5 ]${activeAccordion === `${slice.id}_${index}` ? `[ rotate-180 ]` : `` }`}
                  viewBox={caret.viewBox}
                  aria-hidden="true"
                >
                  <use xlinkHref={caret.url}/>
                </svg>
              </button>
            </h3>
            <StyledPanel
              id={`acc-panel_${slice.id}_${index}`}
              className={`content [ md:pl-12 lg:px-20 ][ text-sm lg:text-lg ] ${activeAccordion === `${slice.id}_${index}` ? `is-active` : ``}`}
              aria-labelledby={`acc-head_${slice.id}_${index}`}
              aria-hidden={ activeAccordion === `${slice.id}_${index}` ? "false" : "true"}
            >
              <div className="content [ pt-4 md:pt-6 lg:pt-7 ]">
                <PrismicRichText field={item.content.richText} />
              </div>
            </StyledPanel>
            <hr className="[ border-0 border-b border-white border-opacity-[24%] ][ my-4 md:my-6 lg:my-7 ]" />
          </React.Fragment>
        ))}
      </div>
    </section>
  )
}


export default Accordions
