import React from 'react';
import LocationModal from './locationModal.js';






const LocationsList = ({ locations, activeLocation, toggleLocation }) => {


  // console.log(`locations`, locations)

  return (
    <ul className="[ mt-8 ]">
      {locations.map((item,index) => (
        <li key={`us-${index}`} className="[ mt-4 ]">
          {item.node.data.locations.length > 0 && item.node.data.locations[0].location.uid != null &&
            <>
              <h3 className="[ text-white font-medium text-lg ]">{ item.node.data.title }</h3>
              <ol>
                {item.node.data.locations.map((loc,index) => {
                  return(
                    <li key={loc.location.id}>
                      <LocationModal loc={loc} toggleLocation={toggleLocation} activeLocation={activeLocation} />
                    </li>
                  )
                })}
              </ol>
            </>
          }
        </li>
      ))}
    </ul>
  )
}


export default LocationsList
