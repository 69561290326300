import React from 'react'
import styled from 'styled-components'
import { PrismicRichText } from '@prismicio/react'
import caret from '../images/caret-down.svg'



const StyledPanel = styled.div`
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.4s cubic-bezier(0, 1, 0, 1) 0s;

  &.is-active {
    max-height: 9999px;
    transition: max-height 0.4s cubic-bezier(1, 0, 1, 0) 0s;
  }
`



const AccordionItem = ({ title, content, id, active, toggleAccordion }) => {


  return (
    <>
      <h3
        className="[ text-white text-lg lg:text-3xl font-medium ]"
      >
        <button
          id={`acc-head_${id}`}
          className="[ flex justify-between items-center ][ w-full ]"
          aria-expanded={ active === id ? "true" : "false"}
          aria-controls={`acc-panel_${id}`}
          onClick={() => toggleAccordion(id)}
        >
          <span className="[ block mr-4 ]">
            {title}
          </span>
          <svg
            className={`[ block w-5 ]${active === id ? `[ rotate-180 ]` : `` }`}
            viewBox={caret.viewBox}
            aria-hidden="true"
          >
            <use xlinkHref={caret.url}/>
          </svg>
        </button>
      </h3>
      <StyledPanel
        id={`acc-panel_${id}`}
        className={`content [ text-gray-300 text-sm lg:text-lg ] ${active === id ? `is-active` : ``}`}
        aria-labelledby={`acc-head_${id}`}
        aria-hidden={ active === id ? "false" : "true"}
      >
        <div className="content [ pt-4 md:pt-6 lg:pt-7 ]">
          <PrismicRichText field={content} />
        </div>
      </StyledPanel>
      <hr className="[ border-0 border-b border-white border-opacity-[24%] ][ my-4 md:my-6 lg:my-7 ]" />
    </>
  )
}


export default AccordionItem
