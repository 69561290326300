import { graphql } from "gatsby";
import * as React from "react";

import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import Layout from "../components/layout";
import Seo from "../components/seo";
import SliceZone from "../components/sliceZone";
// import Bio from "../components/bio";

const PageTemplate = ({ data, location }) => {
  const {
    title,
    body
  } = data?.page?.data || {};

  const {
    prismicId,
    lang,
    alternate_languages,
    id
  } = data?.page || {};

  const global = data?.global?.data || {};

  // console.log(`data`, data)
  // console.log(`title`, title)

  return (
    <Layout location={location} title={title} global={global}>
      <SliceZone
        id={id}
        prismicId={prismicId}
        lang={lang}
        altLangs={alternate_languages}
        // path={path}
        // pageContext={pageContext}
        // slug={doc.slug}
        sliceZone={body}
        // page={doc.page}
        global={global}
        title={title}
        data={data.page}
      />
    </Layout>
  );
};



export const Head = ({ data }) => {
  const {
    title,
    seo_description,
    seo_keywords,
    og_image,
  } = data?.page?.data || {};
  return <Seo title={title} description={seo_description} keywords={seo_keywords} image={og_image} />;
};



export const pageQuery = graphql`
  query PageQuery  {

    global: prismicGlobal {
      data {
       apple_link {
         url
       }
       google_link {
         url
       }
       header_menu {
         anchor_link
         page_link {
           uid
           url
           target
         }
         link_text
       }
       footer_title_1
       footer_menu_1 {
         page_link {
           url
           uid
           target
         }
         link_text
       }
       footer_title_2
       footer_menu_2{
         page_link {
           url
           uid
           target
         }
         link_text
       }
       footer_title_3
       footer_menu_3{
         page_link {
           url
           uid
           target
         }
         link_text
       }
       footer_mobile_menu{
        page_link {
          url
          uid
          target
        }
        link_text
      }
      footer_privacy_menu{
        page_link {
          url
          uid
          target
        }
        link_text
      }
       seo_title
       seo_keywords
       seo_description
       og_image {
         url
         alt
         gatsbyImageData
       }
     }
    }

    page: prismicPage( uid: { eq: "home"} ) {
      _previewable
      uid
      alternate_languages {
        id
        type
        lang
        uid
      }
      lang
      id
      prismicId
      data {
        title
        seo_keywords
        seo_description
        og_image {
          alt
          url
          gatsbyImageData
        }
        body {
          ... on PrismicPageDataBodyHero {
            id
            slice_type
            slice_label
            primary {
              anchor_id
              title1 {
                richText
              }
              video {
                url
              }
              description
              show_app_stores
            }
          }
          ... on PrismicPageDataBodyAppCarousel {
            id
            slice_type
            slice_label
            primary {
              anchor_id
              title1 {
                richText
              }
            }
            items {
              image {
                url
                alt
                gatsbyImageData
              }
              description {
                richText
              }
              disclaimer
              title1 {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyBoxCarousel {
            id
            slice_type
            slice_label
            primary {
              anchor_id
              title1 {
                richText
              }
            }
            items {
              title_opaque
              title_transparent
              description {
                richText
              }
              background {
                url
                alt
                gatsbyImageData
              }
            }
          }
          ... on PrismicPageDataBodyAccordions {
            id
            slice_type
            slice_label
            primary {
              anchor_id
              title1 {
                richText
              }
            }
            items {
              title1 {
                richText
              }
              content {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyLocations {
            id
            slice_type
            slice_label
            primary {
              anchor_id
              title1 {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyContent {
            id
            slice_type
            slice_label
            primary {
              anchor_id
              content {
                richText
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(PageTemplate);
