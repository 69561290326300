import React, { useRef, useLayoutEffect } from "react"
import { createRoot } from 'react-dom/client'
import styled from "styled-components"
import mapboxgl from "mapbox-gl"


const StyledMarker = styled.div`
  background: #FFF;
  border-radius: 20px;
  cursor: pointer;
  position: relative;
  width: 20px;
  height: 20px;
  transition: .2s ease;

  &:before {
    background: rgba(255, 255, 255, 0.24);
    border-radius: 58px;
    content: '';
    position: absolute;
    top: -19px;
    left: -19px;
    width: 58px;
    height: 58px;
    transition: .2s ease;
  }

  &:hover {
    background: #DA5B40;

    &:before {
      background: rgba(218, 91, 64, 0.24);
    }
  }
`

const Marker = ({ map, place, setToolTip, toggleLocation }) => {
  const markerRef = useRef()

  const updateToolTip = (place) => {
    // console.log(`place`, place)
    setToolTip(place)
  }


  useLayoutEffect(() => {
    // const marker = new mapboxgl.Marker(markerRef)
    //   .setLngLat([place.longitude, place.latitude])
    //   .addTo(map)
    const markerDiv = document.createElement("div")
    const markerRoot = createRoot(markerDiv)
    markerRef.current = markerDiv

    markerRoot.render(<StyledMarker onMouseEnter={() => updateToolTip(place)} onMouseLeave={() => updateToolTip(null)} onClick={() => toggleLocation(place.id) } />)

    const marker = new mapboxgl.Marker(markerRef.current)
        .setLngLat([place.longitude, place.latitude])
        .addTo(map);


    return () => marker.remove()
  })

  return (
    <div ref={markerRef} />
  )
}

const Markers = ({ map, places, setToolTip, toggleLocation }) => {
  return (
    <>
      {places &&
        places.map(place => (
          <Marker key={place.name} map={map} place={place} setToolTip={setToolTip} toggleLocation={toggleLocation} />
        ))}
    </>
  )
}

export default Markers