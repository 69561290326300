import React, { useRef, useEffect } from 'react';
import '@splidejs/react-splide/css/core';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { GatsbyImage } from 'gatsby-plugin-image';
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import styled from 'styled-components'
import { PrismicRichText } from '@prismicio/react'
// import logoIcon from '../../images/logo-icon.svg'
// import storeIOS from '../../images/store-ios.svg'
// import storeAndroid from '../../images/store-android.svg'



if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}



const StyledSection = styled.section`
  .splide__arrows {

    @media (min-width: 768px) {
      margin-top: -17px;
      width: 66.6%;
      max-width: 32rem;

      @media (min-width: 1024px) {
        margin-top: -21px;
        width: 58.77%;
      }
      @media (min-width: 1280px) {
        width: 50%;
      }
    }
  }
  .splide__arrow {
    background: #161616;
    border-radius: 6px;
    color: #fff;
    width: 40px;
    height: 40px;

    @media (min-width: 1024px) {
      padding: 9px;
      width: 58px;
      height: 58px;
    }

    &:hover {
      background: #27292B;
    }

    &--prev {
      transform: rotate(180deg);
      margin-right: 10px;
    }
  }
  .splide__pagination {
    @media (min-width: 768px) {
      position: absolute;
      top: 20%;
      right: 0;
    }
  }
  .splide__pagination__page {
    background: #454749;
    border-radius: 10px;
    margin: 0 5px;
    width: 10px;
    height: 10px;

    @media (min-width: 1024px) {
      margin: 0 6px;
    }

    &.is-active {
      background: #fff;
    }
  }
  .splide__slide {
    opacity: 0;

    &.is-visible {
      .SlideTitle,
      .SlideText {
        opacity: 1;
        transform: translateY(0px);
      }
    }
  }
  .SlideTitle {
    opacity: 0;
    transform: translateY(20px);
    transition: .3s ease;
  }
  .SlideText {
    opacity: 0;
    transition: .4s ease .3s;
  }
`

const StyledHeading = styled.div`
  h1,
  h2 {
    color: #fff;
    font-family: "Maison Neue Ext", "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 32px;
    font-weight: 500;
    line-height: 1;
  }
`

const StyledSubHeading = styled.div`
  h2,
  h3 {
    color: #fff;
    font-size: 18px;
    font-family: "Maison Neue Ext", "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: 500;
    line-height: 1.22;

    @media (min-width: 768px) {
      font-size: 32px;
      line-height: 1;
    }
    @media (min-width: 1024px) {
      font-size: 48px;
    }
  }
`


const AppCarousel = ({ slice, global }) => {

  const section = useRef(null)

  // scroll animations
  useEffect(() =>{
    const element = section.current
    const slides = element.querySelectorAll('.splide__slide')
    slides.forEach( (slide, index) => {
      gsap.to(
        slide,
        {
          duration: 0.4,
          delay: index * .1,
          opacity: 1,
          scrollTrigger: {
            trigger: element,
            start: "top 66%",
          },
        }
      )
    })

  }, [])

  // console.log(`app carousel slice`, slice, global)

  return (
    <StyledSection id={slice.primary.anchor_id} className="[ AppCarouselSlice Section ]" ref={section}>
      <div className="container [ !px-0 md:!px-8 lg:!px-12 ]">
        {slice.primary.title1.richText.length > 0 &&
          <StyledHeading className="[ md:hidden mb-6 ][ px-5 ]">
            <PrismicRichText field={slice.primary.title1.richText} />
          </StyledHeading>
        }
        <Splide
          hasTrack={ false }
          options={ {
            type: 'loop',
            gap: '1rem',
            arrowPath: 'm16.9 29.2-2-2 7-7-7-7 2-2 9 9-9 9z',
            autoHeight: true,
            breakpoints: {
              768: {
                padding: { right: '3rem' },
                gap: '.5rem'
              },
            }
          } }
          aria-label={slice.primary.title1.richText.length > 0 ? slice.primary.title1.richText[0].text : ''}
          className="[ max-w-5xl mx-auto ]"
        >
          <div>
            <SplideTrack className="[ mb-4 ]">
              {slice.items.map((item,index) => (
                <SplideSlide
                  key={`slide-${index}`}
                  className="[ bg-gray-900 md:bg-transparent ][ px-5 py-10 md:p-0 ][ flex flex-col justify-between md:flex-row md:items-center ]"
                >
                  <div className="[ md:w-2/3 lg:w-7/12 xl:w-1/2 md:order-1 ][ md:max-w-lg ]">
                    { item.title1.richText.length > 0 &&
                      <StyledSubHeading className="SlideTitle">
                        <PrismicRichText field={item.title1.richText} />
                      </StyledSubHeading>
                    }
                    { item.description.richText.length > 0 &&
                      <div className="SlideText [ mt-6 lg:mt-8 text-sm md:text-lg lg:text-2xl ]">
                        <PrismicRichText field={item.description.richText} />
                      </div>
                    }
                    { item.disclaimer != null &&
                      <p className="SlideText [ mt-6 lg:mt-8 text-xs text-gray-600 ]">{item.disclaimer}</p>
                    }
                  </div>
                  <div className="[ px-8 md:pl-0 mt-8 mx-auto max-w-sm ][ md:w-1/3 lg:w-[37.5%] ]">
                    { item.image.gatsbyImageData != null &&
                      <GatsbyImage
                        image={item.image.gatsbyImageData}
                        alt={item.image.alt ? item.image.alt : ''}
                      />
                    }
                  </div>
                </SplideSlide>
              ))}
            </SplideTrack>

            <ul className="splide__pagination [ z-10 ]"></ul>
            <div className="[ hidden md:flex justify-end ][ absolute top-[20%] -mt- left-0 w-full ]">
              <div className="splide__arrows"></div>
            </div>
          </div>
        </Splide>
      </div>
    </StyledSection>
  )
}


export default AppCarousel
