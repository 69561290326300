import React from 'react'
import logo from '../images/logo.svg'
import storeIOS from '../images/store-ios.svg'
import storeAndroid from '../images/store-android.svg'
import { Link } from 'gatsby'




const Header = ({ global }) => {

  // console.log(`footer`, global)

  return (
    <footer className="[ Footer ]">
      <div className="container">

        <div className="[ md:flex md:items-start md:justify-between ]">

          <Link className="[ text-white ][ relative z-50 ]" to="/">
            <svg className="[ block ]" width="88" height="28" viewBox={logo.viewBox} aria-hidden="true">
              <use xlinkHref={logo.url}/>
            </svg>
            <span className="sr-only">Home</span>
          </Link>

          <div className="[ mt-8 md:mt-0 md:-mx-6 lg:-mx-8 ][ md:flex ]">
            {global.footer_mobile_menu.length >= 1 &&
              <ul className="[ grid grid-cols-2 md:hidden ]">
                {global.footer_mobile_menu.map((item,index) => (
                  <li className="[ mb-4 ]" key={`ft1-${index}`}>
                    { item.page_link.target === '_blank' || item.link_text === 'Email' ? (
                      <a className="[ text-white font-medium lg:text-lg ]" href={item.page_link.url} target="_blank" rel="noopener noreferrer">{item.link_text}</a>
                    ):(
                      <Link className="[ text-white font-medium lg:text-lg ]" to={item.page_link.uid}>{item.link_text}</Link>
                    )}
                  </li>
                ))}
              </ul>
            }

            {global.footer_menu_1.length >= 1 &&
              <ul className="[ hidden md:block ][ md:mx-6 lg:mx-8 ]">
                {global.footer_title_1.length >= 1 &&
                  <li className="[ hidden md:block ][ text-white font-medium lg:text-lg ][ mb-6 ]">{ global.footer_title_1 }</li>
                }
                {global.footer_menu_1.map((item,index) => (
                  <li className="[ mb-4 ]" key={`ft1-${index}`}>
                    { item.page_link.target === '_blank' || item.link_text === 'Email' ? (
                      <a className="[ text-white font-medium lg:text-lg ]" href={item.page_link.url} target="_blank" rel="noopener noreferrer">{item.link_text}</a>
                    ):(
                      <Link className="[ text-white font-medium lg:text-lg ]" to={item.page_link.uid}>{item.link_text}</Link>
                    )}
                  </li>
                ))}
              </ul>
            }
            {global.footer_menu_2.length >= 1 &&
              <ul className="[ hidden md:block ][ md:mx-6 lg:mx-8 ]">
                {global.footer_title_2.length >= 1 &&
                  <li className="[ hidden md:block ][ text-white font-medium lg:text-lg ][ mb-6 ]">{ global.footer_title_2 }</li>
                }
                {global.footer_menu_2.map((item,index) => (
                  <li className="[ mb-4 ]" key={`ft2-${index}`}>
                    { item.page_link.target === '_blank' || item.link_text === 'Email' ? (
                      <a className="[ text-white font-medium lg:text-lg ]" href={item.page_link.url} target="_blank" rel="noopener noreferrer">{item.link_text}</a>
                    ):(
                      <Link className="[ text-white font-medium lg:text-lg ]" to={item.page_link.uid}>{item.link_text}</Link>
                    )}
                  </li>
                ))}
              </ul>
            }
            {global.footer_menu_3.length >= 1 &&
              <ul className="[ hidden md:block ][ md:mx-6 lg:mx-8 ]">
                {global.footer_title_3.length >= 1 &&
                  <li className="[ hidden md:block ][ text-white font-medium lg:text-lg ][ mb-6 ]">{ global.footer_title_3 }</li>
                }
                {global.footer_menu_3.map((item,index) => (
                  <li className="[ mb-4 ]" key={`ft3-${index}`}>
                    { item.page_link.target === '_blank' || item.link_text === 'Email' ? (
                      <a className="[ text-white font-medium lg:text-lg ]" href={item.page_link.url} target="_blank" rel="noopener noreferrer">{item.link_text}</a>
                    ):(
                      <Link className="[ text-white font-medium lg:text-lg ]" to={item.page_link.uid}>{item.link_text}</Link>
                    )}
                  </li>
                ))}
              </ul>
            }
          </div>
        </div>

        <div className="[ mt-4 pt-8 lg:pt-0 lg:mt-28 ][ border-t border-gray-500 lg:border-t-0 ][ lg:flex lg:items-end ]">
          <ul className="[ flex items-center ][ -mx-2 lg:order-1 ]">
            { global.apple_link.url != null &&
              <li className="[ mx-2 ]">
                <a href={global.apple_link.url} target="_blank" rel="noopener noreferrer">
                  <span className="sr-only">Download on the App Store</span>
                  <svg className="[ block h-10 ]" viewBox={storeIOS.viewBox} aria-hidden="true">
                    <use xlinkHref={storeIOS.url}/>
                  </svg>
                </a>
              </li>
            }
            { global.google_link.url != null &&
              <li className="[ mx-2 ]">
                <a href={global.google_link.url} target="_blank" rel="noopener noreferrer">
                  <span className="sr-only">Get it on Google Play</span>
                  <svg className="[ block h-10 ]" viewBox={storeAndroid.viewBox} aria-hidden="true">
                    <use xlinkHref={storeAndroid.url}/>
                  </svg>
                </a>
              </li>
            }
          </ul>

          <div className="[ md:flex items-end justify-between ][ text-2xs text-white ][ mt-8 lg:mt-0 lg:mr-8 ][ lg:flex-1 ]">
            {global.footer_privacy_menu.length >= 1 &&
              <ul className="[ flex flex-wrap md:flex-no-wrap md:order-1 ][ -mx-1 ]">
                {global.footer_privacy_menu.map((item,index) => (
                  <li className="[ mx-1 ]" key={`fp-${index}`}>
                    { item.page_link.target === '_blank' || item.link_text === 'Email' ? (
                      <a className="[ text-white ]" href={item.page_link.url} target="_blank" rel="noopener noreferrer">{item.link_text}</a>
                    ):(
                      <Link className="[ text-white ]" to={item.page_link.uid}>{item.link_text}</Link>
                    )}
                  </li>
                ))}
              </ul>
            }
            <div className="[ text-3xs ][ mt-6 md:mt-0 ][ md:flex-1 md:pr-8 ]">© {new Date().getFullYear()} The Routing Company. Pingo and the Pingo device are trade marks of The Routing Company. All rights reserved.</div>
          </div>
        </div>

      </div>
    </footer>
  )
}


export default Header
