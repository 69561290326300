import React, { useRef, useEffect } from "react"
import mapboxgl from "mapbox-gl"


const ToolTip = ({ map, place }) => {

  const popupRef = useRef()

  useEffect(() => {

    if( !place ) return


    const popup = new mapboxgl.Popup({
      anchor: 'top',
      closeButton: false,
      offset: [0, 15]
    })
      .setLngLat([place?.longitude, place?.latitude])
      .setDOMContent(popupRef.current)
      .addTo(map);

    return () => popup.remove()

  }, [map, place])

  return (
    <div ref={popupRef}>{ place?.location }</div>
  )
}

export default ToolTip