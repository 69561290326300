import React, { useState, useRef, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import mapboxgl from 'mapbox-gl'
import "mapbox-gl/dist/mapbox-gl.css"
import styled from 'styled-components'
import Markers from "./mapMarkers.js"
import ToolTip from './mapToolTip.js'


const StyledMap = styled.div`
  width: 100%;
  height: 75vh;
  min-height: 600px;
`


const MAPBOX_TOKEN = "pk.eyJ1IjoiaGF0Y2hlcnktcGluZ28iLCJhIjoiY2xnbWdveTN5MDN1ZzNocG0zZmg0ZXlmbCJ9.HhXxgYVuMk4EZPP5aeFrRQ"




const LocationsMap = ({ map, setMap, toggleLocation }) => {


  const locations = useStaticQuery(graphql`
    query {
      allPrismicLocation {
        edges {
          node {
            _previewable
            id
            uid
            prismicId
            data {
              title
              location_name
              location {
                latitude
                longitude
              }
            }
          }
        }
      }
    }
  `)

  let places = []
  const mapLocations = locations.allPrismicLocation.edges

  mapLocations.forEach( item => {
    places.push({
      id: item.node.prismicId,
      name: item.node.data.title,
      location: item.node.data.location_name,
      longitude: item.node.data.location.longitude,
      latitude: item.node.data.location.latitude,
    })
  })


  const mapContainerRef = useRef(null)

  const [currentToolTip, setToolTip] = useState(null)

  //const [map, setMap] = useState(null)

  useEffect(() => {

    // init map
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      accessToken: MAPBOX_TOKEN,
      style: "mapbox://styles/mapbox/streets-v11",
      // Empire State Building [lng, lat]
      center: [-99.0302346, 40.6145716],
      zoom: 3,
      attributionControl: false
    })

    // console.log(`map`, map)


    // map theme
    map.setStyle('mapbox://styles/mapbox/dark-v10')

    // map controls
    map.addControl(new mapboxgl.NavigationControl({
      showCompass: false
    }), "bottom-right")

    // geolocate controls
    map.addControl(new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true,
      showUserHeading: true
    }), "bottom-right");

    // map.on('mousemove', e => {
    //   const features = map.queryRenderedFeatures(e.point);
    //   console.log(`features`, features)
      
    //   // if (features.length) {
    //   //   const feature = features[0];

    //   //   // Create tooltip node
    //   //   const tooltipNode = document.createElement('div');
    //   //   ReactDOM.render(<Tooltip feature={feature} />, tooltipNode);

    //   //   // Set tooltip on map
    //   //   tooltipRef.current
    //   //     .setLngLat(e.lngLat)
    //   //     .setDOMContent(tooltipNode)
    //   //     .addTo(map);
    //   // }
    // });

    // map state
    setMap(map)

    return () => map.remove()
  }, [setMap])

  return (
    <>
      <ToolTip map={map} place={currentToolTip} />
      <StyledMap ref={mapContainerRef} className="mapbox">
        {places && map && <Markers map={map} places={places} setToolTip={setToolTip} toggleLocation={toggleLocation} />}
      </StyledMap>
    </>
  )
}


export default LocationsMap
