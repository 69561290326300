import React, {useState, useEffect} from 'react'
import LocationsMobile from '../locationsMobile'
import LocationsDesktop from '../locationsDesktop'



function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}


const Locations = ({ slice, global }) => {

  const isBrowser = typeof window !== 'undefined'

  // console.log(`locations`, locations)

  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0)


  useEffect(() => {

    if (typeof window === 'undefined') return;

    const debouncedHandleResize = debounce(function handleResize() {
      setWidth(window.innerWidth)
    }, 250)

    window.addEventListener('resize', debouncedHandleResize)

    return () => window.removeEventListener('resize', debouncedHandleResize)
  })


  return (
    <section id={slice.primary.anchor_id} className="[ LocationsSlice section ][ overflow-hidden ]">
      <div className="container">
        { width <= 1023 ? (
          <LocationsMobile title={slice.primary.title1} />
        ) : (
          <LocationsDesktop title={slice.primary.title1} />
        )}
      </div>
    </section>
  )
}


export default Locations
