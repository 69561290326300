import * as React from "react";
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews";

import { repositoryConfigs } from "./config/prismic/previews";

import "./src/style.css";

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
    {element}
  </PrismicPreviewProvider>
);

export const onInitialClientRender = () => {
  const img = new Image();
  img.src = `https://bidagent.xad.com/conv/269344?ts=${Date.now()}`;
  const bodyEl = document.getElementsByTagName('body')?.[0];
  bodyEl?.appendChild(img);
};
