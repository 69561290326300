import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { PrismicRichText } from '@prismicio/react'
import { gsap } from "gsap"
import logoIcon from '../../images/logo-icon.svg'
import storeIOS from '../../images/store-ios.svg'
import storeAndroid from '../../images/store-android.svg'



const StyledSection = styled.section`
  &.is-scrolled {
    video {
      transition-delay: 0;
    }
  }
`

const StyledVideo = styled.div`
  &:before {
    background: linear-gradient(180deg, rgba(13, 13, 13, 0) 50%, #0D0D0D 87%), linear-gradient(0deg, rgba(13, 13, 13, 0.62), rgba(0, 0, 0, 0.62));
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    @media (min-width: 1024px) {
      background: linear-gradient(180deg, rgba(13, 13, 13, 0) 50%, #0D0D0D 100%), linear-gradient(0deg, rgba(13, 13, 13, 0.2), rgba(13, 13, 13, 0.2));
    }
  }
`

const StyledContent = styled.div`
  h1,
  h2 {
    color: #fff;
    font-family: "Maison Neue Ext", "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 40px;
    font-weight: 500;
    line-height: 1;

    @media (min-width: 1024px) {
      font-size: 48px;
    }
  }
`

const Hero = ({ slice, global }) => {

  const videoRef = useRef(null);
  const id = slice.primary.anchor_id ? slice.primary.anchor_id : slice.id

  useEffect(() => {

    if (typeof window === 'undefined') return;

    //const video = videoRef.current
    const video = document.getElementById(`vid-${slice.id}`)

    const playFadeVideo = () => {
      //console.log(`loading video`, video.readyState)
        // play video
        // video.play()
        // fade in video
        gsap.to(video, { opacity: 1, duration: 0.7})
        // fade in elements
        const fadeUp = document.querySelectorAll(`.HeroSlice .fade-up`)
        fadeUp.forEach((element, index) => {
          gsap.to(element, {
            delay: 0.2 * ( index + 1 ),
            duration: 0.4,
            opacity: '1',
            y: '0',
          })
        })
        // fade in elements
        const fadeIn = document.querySelector(`.HeroSlice .fade-in`)
        gsap.to(fadeIn, { opacity: 1, duration: 0.4, delay: 0.7})

    }

    setTimeout(
      () => playFadeVideo(),
      500
    )


    // if(video){
    //   video.addEventListener('loadeddata', playFadeVideo)

    //   return () => {
    //     video.removeEventListener("loadeddata", playFadeVideo)
    //   }
    // }


    /*
    // nav scrolling
    let lastScrollTop = 0
    const delta = 5
    const hero = document.querySelector('.HeroSlice')


    function heroScrolled() {
      const st = document.documentElement.scrollTop
      const windowHeight = window.innerHeight
      const documentHeight = document.body.scrollHeight

      if (Math.abs(lastScrollTop - st) <= delta) return

      if( st > lastScrollTop && st > windowHeight * 0.5 ) {
        hero.classList.add('is-scrolled')
        if(videoRef){
          videoRef.current.style.opacity = 0
          videoRef.current.pause()
        }
      } else {
        hero.classList.remove('is-scrolled')
        if(videoRef){
          videoRef.current.style.opacity = 1
          videoRef.current.play()
        }
      }

      lastScrollTop = st
    }

    window.addEventListener('scroll', heroScrolled)


    // remove events
    return () => {
      window.removeEventListener('scroll', heroScrolled)
    }*/
  }, [id, slice.id]);

  // console.log(`hero slice`, slice, global)

  return (
    <StyledSection id={slice.primary.anchor_id ? slice.primary.anchor_id : slice.id} className="[ HeroSlice section ][ flex flex-col justify-end ][ relative overflow-hidden ][ h-screen min-h-[600px] ]">
      <div className="container [ flex flex-wrap md:items-end ]">
        <StyledContent className="[ w-full md:w-1/2 ][ order-1 md:order-0 ][ relative z-10 ][ mb-6 ]">
          { slice.primary.title1.richText.length > 0 &&
            <div className="fade-up [ font-extended font-medium text-white ][ max-w-xs lg:max-w-md ]">
              <PrismicRichText field={slice.primary.title1.richText} />
            </div>
          }
          { slice.primary.description != null &&
            <p className="fade-up [ text-lg lg:text-2xl text-gray-400 ][ mt-8 pr-6 ][ max-w-sm lg:max-w-md ]">{slice.primary.description}</p>
          }
          { slice.primary.show_app_stores === true &&
            <ul className="fade-up [ flex items-center ][ mt-8 ]">
              { global.apple_link.url != null &&
                <li className="[ mr-4 ]">
                  <a href={global.apple_link.url} target="_blank" rel="noopener noreferrer">
                    <span className="sr-only">Download on the App Store</span>
                    <svg className="[ block h-10 ]" viewBox={storeIOS.viewBox} aria-hidden="true">
                      <use xlinkHref={storeIOS.url}/>
                    </svg>
                  </a>
                </li>
              }
              { global.google_link.url != null &&
                <li className="[ mr-4 ]">
                  <a href={global.google_link.url} target="_blank" rel="noopener noreferrer">
                    <span className="sr-only">Get it on Google Play</span>
                    <svg className="[ block h-10 ]" viewBox={storeAndroid.viewBox} aria-hidden="true">
                      <use xlinkHref={storeAndroid.url}/>
                    </svg>
                  </a>
                </li>
              }
            </ul>
          }
        </StyledContent>
        <div className="fade-in [ w-full md:w-1/2 md:order-1 ][ relative z-10 ][ mb-6 ][ opacity-0 ]">
          <svg className="[ text-white ][ w-20 md:w-40 lg:w-60 ][ md:ml-auto ]" viewBox={logoIcon.viewBox} aria-hidden="true">
            <use xlinkHref={logoIcon.url}/>
          </svg>
        </div>
      </div>
      { slice.primary.video.url != null &&
        <StyledVideo>
          <video
            id={`vid-${slice.id}`}
            className="[ absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-0 ][ max-w-none w-auto h-auto min-w-full min-h-full ][ opacity-0 ]"
            playsInline
            autoPlay
            loop
            muted
            ref={videoRef}
          >
              <source src={slice.primary.video.url} type='video/mp4' />
          </video>
        </StyledVideo>
      }
    </StyledSection>
  )
}


export default Hero
