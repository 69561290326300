import React, { useState } from 'react';
import LocationModal from './locationModal.js';






const LocationsListMobile = ({ locations, activeLocation, toggleLocation }) => {

  // create array of locations, not by region
  const allLocations = []
  let region = ''
 
  locations.forEach((item,index) => {
    item.node.data.locations.forEach((loc,index) => {

      allLocations.push({
        'region': region !== item.node.data.title ? item.node.data.title : '',
        'location': loc.location
      })

      region = item.node.data.title
    })
  })
  
  // load more
  const itemsPerClick = 6;
  const [next, setNext] = useState(itemsPerClick);

  const handleMoreLocations = () => {
    setNext(next + itemsPerClick);
  };

  // console.log(`allLocations`, allLocations)

  return (
    <>
      <ul className="[ mt-8 ]">
        {allLocations?.slice(0, next)?.map((item,index) => {
          
          return (
            <li key={`loc-${index}`} className="[ mt-4 ]">
              { item.region !== '' &&
                <h3 className="[ text-white font-medium text-lg ]">{ item.region }</h3>
              }
              <div>
                <LocationModal loc={item} toggleLocation={toggleLocation} activeLocation={activeLocation} />
              </div>
            </li>
          )
        })}
      </ul>
      {next < allLocations?.length && (
          <button
            className="[ block w-full ][ text-lg text-white font-medium text-center ][ border border-white border-opacity-[24%] rounded-lg ][ p-3 mt-7 ]"
            onClick={handleMoreLocations}
          >
            View more
          </button>
      )}
    </>
  )
}


export default LocationsListMobile
