import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import LocationsList from './locationsList.js'
import LocationsListMobile from './locationsListMobile.js'



const StyledList = styled.div`
  @media (min-width: 1024px) {
    height: calc(75vh - 196px);
    min-height: calc( 600px - 196px);

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      border-radius: 0;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 8px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0px;
      -webkit-box-shadow: none;
      background-color: #1f1f1f;
    }
  }
`



const LocationsTabs = ({ updateMapCenter, activeLocation, toggleLocation, isMobile }) => {


  const locations = useStaticQuery(graphql`
    query {
      europe: allPrismicRegion( filter:{ data: { category: { eq: "Europe"}}} sort: { data: { title: ASC }} ) {
        edges {
          node {
            _previewable
            id
            uid
            data {
              title
              category
              locations {
                location {
                  id
                  uid
                  document {
                    ... on PrismicLocation {
                      data {
                        title
                        location_name
                        location {
                          latitude
                          longitude
                        }
                        phone
                        email
                        website {
                          url
                        }
                        map {
                          url
                        }
                        bikes {
                          richText
                        }
                        cost {
                          richText
                        }
                        dogs {
                          richText
                        }
                        payment_options {
                          richText
                        }
                        service_hours {
                          richText
                        }
                        other_items {
                          title1
                          content {
                            richText
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      unitedStates: allPrismicRegion( filter:{ data: { category: { eq: "United States"}}} sort: { data: { title: ASC }} ) {
        edges {
          node {
            _previewable
            id
            uid
            data {
              title
              category
              locations {
                location {
                  id
                  uid
                  document {
                    ... on PrismicLocation {
                      data {
                        title
                        location {
                          latitude
                          longitude
                        }
                        phone
                        email
                        website {
                          url
                        }
                        map {
                          url
                        }
                        bikes {
                          richText
                        }
                        cost {
                          richText
                        }
                        dogs {
                          richText
                        }
                        payment_options {
                          richText
                        }
                        service_hours {
                          richText
                        }
                        other_items {
                          title1
                          content {
                            richText
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)



  const [activeTab, setActiveTab] = useState(0)

  const updateTab = (id) => {
    setActiveTab(id)

    if( updateMapCenter ) {
      if( id > 0) {
        updateMapCenter([7.8514319, 46.733792])
      } else {
        updateMapCenter([-99.0302346, 40.6145716])
      }
    }
  }


  // location counts per region
  const usRegions = locations.unitedStates.edges.filter( item => {
    return item.node.data.locations[0].location.document != null
  })
  let usLocationCount = 0
  usRegions.forEach( region => {
    usLocationCount = usLocationCount + Number(region.node.data.locations.length)
  })

  const euRegions = locations.europe.edges.filter( item => {
    return item.node.data.locations[0].location.document != null
  })
  let euLocationCount = 0
  euRegions.forEach( region => {
    euLocationCount = euLocationCount + Number(region.node.data.locations.length)
  })

  return (
    <>
      <div
        className="[ flex items-end justify-between ][ text-center font-medium ][ md:pr-4 ]"
        role="tablist"
        aria-label="Location Regions"
      >
        <button
          id="tab-locations-us"
          className={`[ w-1/2 ][ flex items-center justify-center ][ w-full ][ border-b ${activeTab === 0 ? 'border-white text-white' : 'border-white border-opacity-[24%] text-gray-300'} ][ py-2 ][ hover:text-white transition ]`}
          type="button"
          role="tab"
          aria-selected={activeTab === 0 ? "true" : "false"}
          aria-controls="locations-us"
          onClick={() => updateTab(0)}
        >
          <span className={`[ block ][ text-lg ][ mr-3 ]`}>United States</span>
          <span className="[ block ][ text-base ][ h-[26px] leading-[28px] w-9 ][ bg-gray-900 rounded-2xl ]">{usLocationCount}</span>
        </button>
        <button
          id="tab-locations-eu"
          className={`[ w-1/2 ][ flex items-center justify-center ][ w-full ][ border-b ${activeTab === 1 ? 'border-white text-white' : 'border-white border-opacity-[24%] text-gray-300'} ][ py-2 ][ hover:text-white transition ]`}
          type="button"
          role="tab"
          aria-selected={activeTab === 1 ? "true" : "false"}
          aria-controls="locations-eu"
          onClick={() => updateTab(1)}
        >
          <span className={`[ block ][ text-lg ][ mr-3 ]`}>Europe</span>
          <span className="[ block ][ text-base ][ h-[26px] leading-[28px] w-9 ][ bg-gray-900 rounded-2xl ]">{euLocationCount}</span>
        </button>
      </div>

      <StyledList
        id="locations-us"
        className="[ overflow-x-hidden overflow-y-auto ][ md:pr-4 ]"
        role="tabpanel"
        tabIndex="0"
        aria-labelledby="tab-locations-us"
        hidden={ activeTab === 0 ? "" : "hidden"}
      >
        { isMobile ? (
          <LocationsListMobile locations={locations.unitedStates.edges} activeLocation={activeLocation} toggleLocation={toggleLocation} />
        ): (
          <LocationsList locations={locations.unitedStates.edges} activeLocation={activeLocation} toggleLocation={toggleLocation} />
        )}
      </StyledList>

      <StyledList
        id="locations-eu"
        className="[ overflow-x-hidden overflow-y-auto ][ md:pr-4 ]"
        role="tabpanel"
        tabIndex="0"
        aria-labelledby="tab-locations-eu"
        hidden={ activeTab === 1 ? "" : "hidden"}
      >
        { isMobile ? (
          <LocationsListMobile locations={locations.europe.edges} activeLocation={activeLocation} toggleLocation={toggleLocation} />
        ): (
          <LocationsList locations={locations.europe.edges} activeLocation={activeLocation} toggleLocation={toggleLocation} />
        )}
      </StyledList>
    </>
  )
}


export default LocationsTabs
