import React, { useEffect, useRef } from 'react';
import '@splidejs/react-splide/css/core';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
// import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components'
import { PrismicRichText } from '@prismicio/react'
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Arrow from '../../images/arrow.svg'



if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}



const StyleSection = styled.section`
  .splide__pagination__page {
    background: #454749;
    border-radius: 10px;
    margin: 0 5px;
    width: 10px;
    height: 10px;

    @media (min-width: 1024px) {
      margin: 0 6px;
    }

    &.is-active {
      background: #fff;
    }
  }
  .splide__arrow {
    cursor: none;
    display: block;
    width: 50%;
    height: 100%;

    svg {
      display: none;
    }
  }

  .splide__slide {
    opacity: 0;

    &.is-visible {
      .SlideTitle,
      .SlideText {
        opacity: 1;
        transform: translateY(0px);
      }
    }
  }

  .SlideTitle {
    opacity: 0;
    transform: translateY(20px);
    transition: .3s ease;
  }
  .SlideText {
    opacity: 0;
    transition: .4s ease .3s;
  }
`

const StyledHeading = styled.div`
  h1,
  h2 {
    color: #fff;
    font-family: "Maison Neue Ext", "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 32px;
    font-weight: 500;
    line-height: 1;

    @media (min-width: 1024px) {
      font-size: 48px;
    }
  }
`



const BoxCarousel = ({ slice, global }) => {

  // console.log(`box carousel slice`, slice)
  const section = useRef(null)
  const sliderRef = useRef(null)
  const arrowRef = useRef(null)


  // custom arrows
  useEffect(() => {

    if (typeof window === 'undefined') return;

    const cursor = document.getElementById(`cursor-${slice.id}`)
    const slider = document.getElementById(`arrows-${slice.id}`)
    const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 }
    const mouse = { x: pos.x, y: pos.y }
    const speed = 1

    const xSet = gsap.quickSetter(cursor, "x", "px")
    const ySet = gsap.quickSetter(cursor, "y", "px")

    gsap.set(cursor, {xPercent: -50, yPercent: -50, scale: 0.5, opacity: 0})


    const mouseMove = (e) => {
      if( e.x < window.innerWidth / 2 ) {
        gsap.to(arrowRef.current, { scale: -1, duration: 0.1 })
      } else {
        gsap.to(arrowRef.current, { scale: 1, duration: 0.1 })
      }
      mouse.x = e.x;
      mouse.y = e.y;
    }

    const tickerUpdate = () => {
      var dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio())
      pos.x += (mouse.x - pos.x) * dt
      pos.y += (mouse.y - pos.y) * dt
      xSet(pos.x)
      ySet(pos.y)
    }

    const handleMouseOut = () => {
      slider.removeEventListener("mousemove", mouseMove)
      document.body.style.cursor = "default"
      gsap.ticker.remove(tickerUpdate)
      gsap.to(cursor, { scale: 0.5, opacity: 0, duration: 0.1 })
    }
    const handleMouseOver = () => {
      slider.addEventListener("mousemove", mouseMove)
      document.body.style.cursor = "none"
      gsap.ticker.add(tickerUpdate)
      gsap.to(cursor, { scale: 1, opacity: 1, duration: 0.1 })
    }

    if( slider ) {
      slider.addEventListener("mouseenter", handleMouseOver)
      slider.addEventListener("mouseleave", handleMouseOut)

      return () => {
        slider.removeEventListener("mouseenter", handleMouseOver)
        slider.removeEventListener("mouseleave", handleMouseOut)
     }
    }

  }, [slice.id])


  // scroll animations
  useEffect(() =>{
    const element = section.current
    gsap.to(
      element.querySelector('.SectionTitle'),
      {
        duration: 0.4,
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: element,
          start: "top 66%",
        },
      }
    )
    const slides = element.querySelectorAll('.splide__slide')
    slides.forEach( (slide, index) => {
      gsap.to(
        slide,
        {
          duration: 0.4,
          delay: 0.2 + (index * .1),
          opacity: 1,
          scrollTrigger: {
            trigger: element,
            start: "top 66%",
          },
        }
      )
    })

  }, [])

  return (
    <StyleSection id={slice.primary.anchor_id} className="[ BoxCarouselSlice Section ][ pb-4 ]" ref={section}>
      <div className="container [ !px-0 md:!px-8 lg:!px-12 ]">
        {slice.primary.title1.richText.length > 0 &&
          <StyledHeading className="SectionTitle fade-up [ mb-6 md:mb-8 lg:mb-12 ][ px-5 md:px-0 ]">
            <PrismicRichText field={slice.primary.title1.richText} />
          </StyledHeading>
        }
        <Splide
          id={`splide-${slice.id}`}
          ref={sliderRef}
          hasTrack={ false }
          options={ {
            type: 'loop',
            perPage: 2,
            gap: '2rem',
            lazyLoad: 'nearby',
            breakpoints: {
              768: {
                arrows: false,
                padding: { right: '5rem' },
                perPage: 1,
                gap: '1rem'
              },
              540: {
                padding: { right: '3rem' },
                gap: '0.5rem'
              }
            }
          } }
          aria-label={slice.primary.title1.richText.length > 0 ? slice.primary.title1.richText[0].text : ''}
        >
          <div>
            <SplideTrack>
              {slice.items.map((item,index) => (
                <SplideSlide
                  key={`slide-${index}`}
                  className="[ pt-[106%] sm:pt-[98%] md:pt-[44%] lg:pt-[37%] xl:pt-[30%] ][ relative ]"
                >
                  <div className="[ absolute inset-0 ]">
                    <div className="[ w-full h-full ][ px-5 py-10 lg:p-12 ][ flex flex-col justify-between ][ relative z-10 ]">
                      {slice.primary.title1.richText.length > 0 && slice.primary.title1.richText[0].type === 'heading2' ? (
                        <h3 className="SlideTitle [ text-4xl lg:text-5xl ][ text-white font-medium ]">
                          { item.title_opaque != null &&
                            <span className="block">{item.title_opaque}</span>
                          }
                          { item.title_transparent != null &&
                            <span className="block [ opacity-50 ]">{item.title_transparent}</span>
                          }
                        </h3>
                      ) : (
                        <h2 className="SlideTitle [ text-4xl lg:text-5xl ][ text-white font-medium ]">
                          { item.title_opaque != null &&
                            <span className="block">{item.title_opaque}</span>
                          }
                          { item.title_transparent != null &&
                            <span className="block [ opacity-50 ]">{item.title_transparent}</span>
                          }
                        </h2>
                      )}
                      { item.description.richText.length > 0 &&
                        <div className="SlideText [ mt-6 lg:mt-8 ][ text-sm lg:text-lg text-white ][ max-w-md ]">
                          <PrismicRichText field={item.description.richText} />
                        </div>
                      }
                    </div>
                    { item.background.gatsbyImageData != null &&
                      <div className="[ absolute z-0 inset-0 ]">
                        <img
                          data-splide-lazy={item.background.url}
                          className="[ block ][ object-cover object-center w-full h-full ]"
                          alt={item.background.alt ? item.background.alt : ''}
                        />
                      </div>
                    }
                  </div>
                </SplideSlide>
              ))}
            </SplideTrack>

            <ul className="splide__pagination [ absolute -bottom-8 left-0 z-10 ][ w-full ]"></ul>
            <div className="[ hidden md:block ][ absolute inset-0 ]">
              <div id={`arrows-${slice.id}`} className="splide__arrows [ w-full h-full ][ flex ]"></div>
            </div>

          </div>
        </Splide>
      </div>
      <div id={`cursor-${slice.id}`} className="[ fixed top-0 left-0 scale-0 ][ will-change-transform pointer-events-none ][ w-20 h-20 ][ bg-white rounded-full ][ flex items-center justify-center ]">
        <svg
          ref={arrowRef}
          className="[ text-black ]"
          viewBox={Arrow.viewBox}
          aria-hidden="true"
          width="20" height="22"
        >
          <use xlinkHref={Arrow.url}/>
        </svg>
      </div>
    </StyleSection>
  )
}


export default BoxCarousel
