import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { PrismicRichText } from '@prismicio/react'
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import LocationsTabs from './locationsTabs.js'
import LocationsMap from './locationsMap'



if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}



const Heading = styled.div`
  h1,
  h2 {
    color: #fff;
    font-family: "Maison Neue Ext", "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 32px;
    font-weight: 500;
    line-height: 1;

    @media (min-width: 1024px) {
      font-size: 48px;
    }
  }
`

const StyledMap = styled.div`
  opacity: 0;
  transform: translateX(50px);
`





const LocationsDesktop = ({ title }) => {

  // section ref
  const section = useRef(null)

  // map state
  const [map, setMap] = useState(null)

  // location state
  const [activeLocation, setActiveLocation] = useState(null)
  const toggleLocation = (id) => {
    // console.log(`toggleLocation`, id)
    activeLocation === id ? setActiveLocation(null) : setActiveLocation(id)
  }


  // scroll animations
  useEffect(() =>{
    const element = section.current
    gsap.to(
      element.querySelector('.SectionTitle'),
      {
        duration: 0.4,
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: element,
          start: "top 66%",
        },
      }
    )
    gsap.to(
      element.querySelector('.SectionMap'),
      {
        duration: 0.4,
        delay: 0.3,
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: element,
          start: "top 66%",
        },
      }
    )
  }, [])


  const updateMapCenter = (coords) => {
    if( map ) {
      map.setCenter(coords)
    }
  }


  return (
    <div className="[ grid grid-cols-2 gap-8 ][ relative ]" ref={section}>
      <div>
        {title.richText.length > 0 &&
          <Heading className="SectionTitle fade-up [ mb-10 lg:mb-14 ]">
            <PrismicRichText field={title.richText} />
          </Heading>
        }
        <LocationsTabs updateMapCenter={updateMapCenter} activeLocation={activeLocation} toggleLocation={toggleLocation} />
      </div>
      <StyledMap className="SectionMap">
        <LocationsMap map={map} setMap={setMap} toggleLocation={toggleLocation} />
      </StyledMap>
    </div>
  )
}


export default LocationsDesktop
