exports.linkResolver = (doc) => {

  if (doc.type === 'page' && doc.uid === 'home') {
    return `/`
  }

  if (doc.type === 'page' && doc.uid !== 'home') {
    return `/${doc.uid}`
  }

  return `/`
};
