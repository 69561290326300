import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import { Link } from "gatsby";
import { PrismicLink } from '@prismicio/react';
import logo from '../images/logo.svg'
import caretRight from '../images/caret-right.svg'




function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}



const StyledHeader = styled.header`
  &.nav-up {
    transform: translateY(-100%);
    transition: all 0.3s linear 0s;
  }
  &.nav-down {
    transform: translateY(0px);
    transition: all 0.3s linear 0s;
  }
`

const StyledNav = styled.nav`

  &[aria-hidden="true"] {
    transition: .2s linear;
  }
  &[aria-hidden="false"] {
    transition: .4s cubic-bezier(.55,.055,.675,.19);
    transform: translateX(0);
    visibility: visible;
  }
`

const StyledBurger = styled.button`
  > span:first-child {
    position: relative;
    display: block;
    width: 18px;
    height: 12px;

    > span {
      transition-timing-function: cubic-bezier(.55,.055,.675,.19);
      transition-duration: 75ms;
      position: absolute;
      width: 18px;
      height: 2px;
      background-color: #fff;
      top: 50%;
      display: block;
      margin-top: -1px;

      &:before,
      &:after {
        display: block;
        content: "";
        position: absolute;
        width: 18px;
        height: 2px;
          background-color: #fff;
      }

      &:before {
        top: -5px;
        transition: top 75ms ease .12s,opacity 75ms ease;
      }

      &:after {
        bottom: -5px;
        transition: bottom 75ms ease .12s,transform 75ms cubic-bezier(.55,.055,.675,.19);
      }
    }
  }

  &[aria-expanded="true"] {
    > span:first-child {
      transition-delay: .12s;
      transition-timing-function: cubic-bezier(.215,.61,.355,1);
      transform: rotate(45deg);

      > span {
        &:before {
          top: 0;
          transition: top 75ms ease,opacity 75ms ease .12s;
          opacity: 0;
        }
        &:after {
          bottom: 0;
          transition: bottom 75ms ease,transform 75ms cubic-bezier(.215,.61,.355,1) .12s;
          transform: rotate(-90deg);
        }
      }
    }
  }
`


const Header = ({ global }) => {

  // console.log(`header`, global)

  const isBrowser = typeof window !== 'undefined'

  // menu state
  const [menuState, setMenuState] = useState(false)

  const toggleMenu = () => {
    setMenuState(!menuState)
  }

  // browser width
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0)


  const siteUrl = typeof window !== 'undefined' ? `${window.location.protocol}//${window.location.host}` : '';


  // responsive menu state
  useEffect(() => {

    if (typeof window === 'undefined') return;


    // window resizing
    const debouncedHandleResize = debounce(function handleResize() {
      setWidth(window.innerWidth)
    }, 100)

    debouncedHandleResize()

    window.addEventListener('resize', debouncedHandleResize)



    // nav scrolling
    let lastScrollTop = 0
    const delta = 5
    const header = document.querySelector('.Header')
    const headerHeight = header.clientHeight


    function navScrolled() {
      const st = document.documentElement.scrollTop
      const windowHeight = window.innerHeight
      const documentHeight = document.body.scrollHeight

      if (Math.abs(lastScrollTop - st) <= delta) return

      if (st > lastScrollTop && st > headerHeight) {
        // Scroll Down
        header.classList.remove('nav-down')
        header.classList.add('nav-up')
      } else {
        // Scroll Up
        if (st + windowHeight < documentHeight) {
          header.classList.add('nav-down')
          header.classList.remove('nav-up')
        }
        if (st === 0) {
          header.classList.remove('nav-down')
        }
      }

      lastScrollTop = st
    }

    window.addEventListener('scroll', navScrolled)


    // remove events
    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
      window.removeEventListener('scroll', navScrolled)
    }
  })

  return (
    <StyledHeader className="[ Header ][ fixed top-0 left-0 z-50 ][ w-full ]">
      <div className="container [ !py-3 ][ flex items-center justify-between ][ h-16 ]">
        <Link className="[ text-white ][ relative z-50 ]" to="/">
          <svg className="[ block ]" width="88" height="28" viewBox={logo.viewBox} aria-hidden="true">
            <use xlinkHref={logo.url}/>
          </svg>
          <span className="sr-only">Home</span>
        </Link>
        { global.header_menu.length > 0 &&
          <>
            <StyledNav id="main-nav" className="[ fixed lg:relative top-0 right-0 lg:top-auto lg:right-auto z-40 ][ w-full h-screen lg:w-auto lg:h-auto ][ overflow-x-hidden overflow-y-auto ][ bg-black lg:bg-transparent ][ pt-16 lg:p-0 ][ flex flex-col justify-between ][ translate-x-full visibiliy-hidden lg:translate-x-0 lg:visibility-visible ]" role="navigation" aria-hidden={width < 1024 ? !menuState : null}>
              <ul className="[ border-t border-white border-opacity-[24%] lg:border-0 ][ lg:flex lg:items-center lg:-mx-4 ]">
                {global.header_menu.map((item,index) => (
                  <li className="[ lg:mx-4 ]" key={`link-${index}`}>
                    {item.anchor_link != null ? (
                      <a className="[ flex items-center justify-between ][ px-6 py-5 lg:p-0 lg:p-3 lg:rounded-lg ][ text-white text-lg lg:text-base text-white font-medium ][ hover:bg-gray-700/25 transition ]" href={`${siteUrl}/${item.anchor_link}`} onClick={() => setMenuState(false)}>
                        <span>{item.link_text}</span>
                        <svg className="[ block lg:hidden ]" width="7" height="12" viewBox={caretRight.viewBox} aria-hidden="true">
                          <use xlinkHref={caretRight.url}/>
                        </svg>
                      </a>
                    ) : (
                      <PrismicLink className="[ flex items-center justify-between ][ px-6 py-5 lg:p-3 lg:rounded-md ][ text-white text-lg lg:text-base text-white font-medium ]" field={item.page_link}>
                        <span>{item.link_text}</span>
                        <svg className="[ block lg:hidden ]" width="7" height="12" viewBox={caretRight.viewBox} aria-hidden="true">
                          <use xlinkHref={caretRight.url}/>
                        </svg>
                      </PrismicLink>
                    )}
                  </li>
                ))}
              </ul>
              <ul className="[ border-t border-white border-opacity-[24%] ][ px-6 py-2 ][ lg:hidden ]">
                { global.apple_link.url != null &&
                  <li className="[ my-4 ]">
                    <a href={global.apple_link.url} className="[ block ][ bg-white rounded-lg ][ text-black text-lg font-medium text-center ][ p-3 ]" target="_blank" rel="noopener noreferrer">Download on iOS</a>
                  </li>
                }
                { global.google_link.url != null &&
                  <li className="[ my-4 ]">
                    <a href={global.google_link.url}  className="[ block ][ text-white text-lg font-medium text-center ][ p-3 ]" target="_blank" rel="noopener noreferrer">Download on Google Play</a>
                  </li>
                }
              </ul>
            </StyledNav>
            <StyledBurger className="[ relative z-50 ][ p-5 -mr-5 ][ lg:hidden ]" aria-controls="main-nav" aria-expanded={menuState} onClick={() => toggleMenu()}>
              <span><span></span></span>
              <span className="sr-only">Toggle Menu</span>
            </StyledBurger>
          </>
        }
      </div>
    </StyledHeader>
  )
}


export default Header
