import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import { PrismicRichText } from '@prismicio/react'
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import LocationsTabs from './locationsTabs.js'


if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}




const Heading = styled.div`
  h1,
  h2 {
    color: #fff;
    font-family: "Maison Neue Ext", "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 32px;
    font-weight: 500;
    line-height: 1;

    @media (min-width: 1024px) {
      font-size: 48px;
    }
  }
`



const LocationsMobile = ({ title }) => {

  // section ref
  const section = useRef(null)

  // location state
  const [activeLocation, setActiveLocation] = useState(null)
  const toggleLocation = (id) => {
    activeLocation === id ? setActiveLocation(null) : setActiveLocation(id)
  }


  // scroll animations
  useEffect(() =>{
    const element = section.current
    gsap.to(
      element.querySelector('.SectionTitle'),
      {
        duration: 0.4,
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: element,
          start: "top 66%",
        },
      }
    )
  }, [])

  return (
    <div ref={section}>
      {title.richText.length > 0 &&
        <Heading className="SectionTitle fade-up [ mb-6 md:mb-8 lg:mb-12 ]">
          <PrismicRichText field={title.richText} />
        </Heading>
      }
      <LocationsTabs updateMapCenter={null} activeLocation={activeLocation} toggleLocation={toggleLocation} isMobile={true} />
    </div>
  )
}


export default LocationsMobile
